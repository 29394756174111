
import moment from 'moment'
import { dayOfWeekStr } from '@/utils/dateUtil'

var lodash = require('lodash')
// var lodash = require('lodash')

/**
 * 合計料金の計算
 * @param {*} airList
 * @param {*} trnList
 * @param {*} hotelList
 * @param {*} busList
 * @param {*} carList
 * @param {*} optList
 */
export function sumPrice(airList, trnList, hotelList, busList, carList, optList) {
  var sumPrice = 0
  airList.forEach(item => {
    sumPrice = sumPrice + parseInt(item.pln_price) * item.qnty
  })

  trnList.forEach(item => {
    sumPrice = sumPrice + parseInt(item.trn_price) * item.qnty
  })

  hotelList.forEach(item => {
    sumPrice = sumPrice + parseInt(item.totalPrice)
  })

  busList.forEach(item => {
    sumPrice = sumPrice + parseInt(item.totalPrice)
  })

  carList.forEach(item => {
    sumPrice = sumPrice + parseInt(item.totalPrice)
  })
  optList.forEach(item => {
    sumPrice = sumPrice + parseInt(item.totalPrice)
  })
  return sumPrice
}

/**
 * 予定表リスト  ->  日程表リスト
 * @param {*} airList
 * @param {*} trnList
 * @param {*} hotelList
 * @param {*} busList
 * @param {*} carList
 * @param {*} optList
 * @returns
 */
export function yoteiToNittei(airList, trnList, hotelList, busList, carList, optList) {
  // すべての日付を取得
  var timestampMap = getTimestamps(airList, trnList, hotelList, busList, carList, optList)

  var nitteiDictList = []
  timestampMap.forEach(value => {
    nitteiDictList.push({
      ymd: moment(new Date(value)).format('YYYY/MM/DD') + '（' + dayOfWeekStr(value) + '）',
      list: getNittei(new Date(value), airList, trnList, hotelList, busList, carList, optList)
    })
  })

  nitteiDictList = lodash.sortBy(nitteiDictList, o => { return o.ymd })

  var number = 1
  nitteiDictList.forEach(value => {
    value.ymd = value.ymd + number + '日目'
    number = number + 1
  })

  //   lodash.forEach(nitteiDictList, function(nitteiDict) {
  //     nitteiDict.list.forEach(item => {
  //       console.log(nitteiDict.ymd + '   ' + item.rowInfor.sort + '  ' + item.rowInfor.type)
  //     })
  //   })

  return nitteiDictList
}

/**
 * 日付によって日程表を取得する
 * @param {*} timestamp
 * @param {*} airList
 * @param {*} trnList
 * @param {*} hotelList
 * @param {*} busList
 * @param {*} carList
 * @param {*} optList
 */
function getNittei(timestamp, airList, trnList, hotelList, busList, carList, optList) {
  var list = []
  if (airList !== null) {
    airList.forEach(item => {
      item = lodash.cloneDeep(item)
      if (moment(timestamp).format('YYYYMMDD') === moment(new Date(item.dep_time)).format('YYYYMMDD')) {
        item.rowInfor = {
          sort: moment(new Date(item.dep_time)).valueOf(),
          type: 'air'
        }
        list.push(item)
      }
    })
  }

  if (trnList !== null) {
    trnList.forEach(item => {
      item = lodash.cloneDeep(item)
      if (moment(timestamp).format('YYYYMMDD') === moment(new Date(item.dep_time)).format('YYYYMMDD')) {
        item.rowInfor = {
          sort: moment(new Date(item.dep_time)).valueOf(),
          type: 'trn'
        }
        list.push(item)
      }
    })
  }

  if (hotelList !== null) {
    hotelList.forEach(item => {
      item = lodash.cloneDeep(item)
      var checkInDate = null
      var checkOutDate = null
      if (item.checkIn instanceof Date) {
        checkInDate = item.checkIn
        checkOutDate = item.checkOut
      } else {
        checkInDate = new Date(item.checkIn)
        checkOutDate = new Date(item.checkOut)
      }

      if (parseInt(moment(checkInDate).format('YYYYMMDD')) <= parseInt(moment(timestamp).format('YYYYMMDD')) &&
       parseInt(moment(timestamp).format('YYYYMMDD')) <= parseInt(moment(checkOutDate).format('YYYYMMDD'))) {
        item.rowInfor = {
          sort: moment(timestamp).valueOf(),
          type: 'hotel'
        }
        if (parseInt(moment(checkInDate).format('YYYYMMDD')) === parseInt(moment(timestamp).format('YYYYMMDD'))) {
          item.rowInfor.checkState = 'チェックイン'
          item.rowInfor.checkTime = moment(checkInDate).format('HH:mm')
        }

        if (parseInt(moment(checkOutDate).format('YYYYMMDD')) === parseInt(moment(timestamp).format('YYYYMMDD'))) {
          item.rowInfor.checkState = 'チェックアウト'
          item.rowInfor.checkTime = moment(checkOutDate).format('HH:mm')
        }

        list.push(item)
      }
    })
  }

  if (busList !== null) {
    busList.forEach(item => {
      item = lodash.cloneDeep(item)
      var busStartTimeDate = null
      if (item.busStartTime instanceof Date) {
        busStartTimeDate = item.busStartTime
      } else {
        busStartTimeDate = new Date(item.busStartTime)
      }

      if (moment(timestamp).format('YYYYMMDD') === moment(busStartTimeDate).format('YYYYMMDD')) {
        item.rowInfor = {
          sort: moment(busStartTimeDate).valueOf(),
          type: 'bus'
        }
        list.push(item)
      }
    })
  }

  if (carList !== null) {
    carList.forEach(item => {
      item = lodash.cloneDeep(item)
      var rtcStarttimeDate = null
      var rtcEndtimeDate = null
      if (item.checkIn instanceof Date) {
        rtcStarttimeDate = item.rtcStarttime
        rtcEndtimeDate = item.rtcEndtime
      } else {
        rtcStarttimeDate = new Date(item.rtcStarttime)
        rtcEndtimeDate = new Date(item.rtcEndtime)
      }

      if (parseInt(moment(rtcStarttimeDate).format('YYYYMMDD')) <= parseInt(moment(timestamp).format('YYYYMMDD')) &&
       parseInt(moment(timestamp).format('YYYYMMDD')) <= parseInt(moment(rtcEndtimeDate).format('YYYYMMDD'))) {
        item.rowInfor = {
          sort: moment(timestamp).valueOf(),
          type: 'car'
        }

        if (parseInt(moment(rtcStarttimeDate).format('YYYYMMDD')) === parseInt(moment(timestamp).format('YYYYMMDD'))) {
          item.rowInfor.checkState = '出発'
          item.rowInfor.checkTime = moment(rtcStarttimeDate).format('HH:mm')
          item.rowInfor.rtcArear = item.rtcStartArear
        }

        if (parseInt(moment(rtcEndtimeDate).format('YYYYMMDD')) === parseInt(moment(timestamp).format('YYYYMMDD'))) {
          item.rowInfor.checkState = '返却'
          item.rowInfor.checkTime = moment(rtcEndtimeDate).format('HH:mm')
          item.rowInfor.rtcArear = item.rtcEndArear
        }

        list.push(item)
      }
    })
  }

  if (optList !== null) {
    optList.forEach(item => {
      item = lodash.cloneDeep(item)
      var startTimeDate = item.startTime
      if (item.startTime instanceof Date) {
        startTimeDate = item.startTime
      } else {
        startTimeDate = new Date(item.startTime)
      }

      if (moment(timestamp).format('YYYYMMDD') === moment(startTimeDate).format('YYYYMMDD')) {
        item.rowInfor = {
          sort: moment(startTimeDate).valueOf(),
          type: 'opt'
        }
        list.push(item)
      }
    })
  }

  list = lodash.sortBy(list, o => { return o.sort })
  return list
}

/**
 * すべての日付を取得
 * @param {*} airList
 * @param {*} trnList
 * @param {*} hotelList
 * @param {*} busList
 * @param {*} carList
 * @param {*} optList
 * @returns
 */
function getTimestamps(airList, trnList, hotelList, busList, carList, optList) {
  var timestampMap = new Map()
  if (airList !== null) {
    airList.forEach(item => {
      timestampMap.set(moment(new Date(item.dep_time)).format('YYYYMMDD'), item.dep_time)
    })
  }

  if (trnList !== null) {
    trnList.forEach(item => {
      timestampMap.set(moment(new Date(item.dep_time)).format('YYYYMMDD'), item.dep_time)
    })
  }

  if (hotelList !== null) {
    hotelList.forEach(item => {
      var checkInDate = null
      var checkOutDate = null
      if (item.checkIn instanceof Date) {
        checkInDate = item.checkIn
        checkOutDate = item.checkOut
      } else {
        checkInDate = new Date(item.checkIn)
        checkOutDate = new Date(item.checkOut)
      }

      checkInDate = new Date(moment(checkInDate).format('YYYY/MM/DD'))
      checkOutDate = new Date(moment(checkOutDate).format('YYYY/MM/DD'))
      while (moment(checkInDate).valueOf() <= moment(checkOutDate).valueOf()) {
        timestampMap.set(moment(checkInDate).format('YYYYMMDD'), checkInDate)
        checkInDate = new Date(moment(checkInDate).add(1, 'days'))
      }
    })
  }

  if (busList !== null) {
    busList.forEach(item => {
      var busStartTime = item.busStartTime
      if (busStartTime instanceof Date) {
        timestampMap.set(moment(busStartTime).format('YYYYMMDD'), busStartTime)
      } else {
        timestampMap.set(moment(new Date(busStartTime)).format('YYYYMMDD'), new Date(busStartTime))
      }
    })
  }

  if (carList !== null) {
    carList.forEach(item => {
      var rtcStarttimeDate = null
      var rtcEndtimeDate = null
      if (item.checkIn instanceof Date) {
        rtcStarttimeDate = item.rtcStarttime
        rtcEndtimeDate = item.rtcEndtime
      } else {
        rtcStarttimeDate = new Date(item.rtcStarttime)
        rtcEndtimeDate = new Date(item.rtcEndtime)
      }

      rtcStarttimeDate = new Date(moment(rtcStarttimeDate).format('YYYY/MM/DD'))
      rtcEndtimeDate = new Date(moment(rtcEndtimeDate).format('YYYY/MM/DD'))
      while (moment(rtcStarttimeDate).valueOf() <= moment(rtcEndtimeDate).valueOf()) {
        timestampMap.set(moment(rtcStarttimeDate).format('YYYYMMDD'), rtcStarttimeDate)
        rtcStarttimeDate = new Date(moment(rtcStarttimeDate).add(1, 'days'))
      }
    })
  }

  if (optList !== null) {
    optList.forEach(item => {
      var startTimeDate = item.startTime
      if (startTimeDate instanceof Date) {
        timestampMap.set(moment(startTimeDate).format('YYYYMMDD'), startTimeDate)
      } else {
        timestampMap.set(moment(new Date(startTimeDate)).format('YYYYMMDD'), new Date(startTimeDate))
      }
    })
  }

  return timestampMap
}

