/**
 * 曜日取得
 * @param {YYYY/MM/DD} dateStr
 * @returns 曜日
 */
export function dayOfWeekStr(dateStr) {
  var date = new Date(dateStr)
  var dayOfWeek = date.getDay()
  var dayOfWeekStr = ['日', '月', '火', '水', '木', '金', '土'][dayOfWeek]
  return dayOfWeekStr
}

/**
 *
 * @param {YYYY*MM*DD} dateStr
 * @returns Data
 */
export function strTostrYMD(dateStr) {
  return dateStr.slice(0, 4) + '/' + dateStr.slice(5, 7) + '/' + dateStr.slice(8, 10)
}

/**
 *
 * @param {YYYY*MM*DD} dateStr
 * @returns Data
 */
export function strTostrYMDHm(dateStr) {
  return dateStr.slice(0, 4) + '/' + dateStr.slice(5, 7) + '/' + dateStr.slice(8, 10) + ' ' + dateStr.slice(11, 13) + ':' + dateStr.slice(14, 16)
}

/**
 * 日付かどうかの判定
 * @param {*} year
 * @param {*} month
 * @param {*} day
 * @returns
 */
export function isValidDate(year, month, day) {
  var intYear = parseInt(year)
  var intMonth = parseInt(month)
  var intDay = parseInt(day)

  if (isNaN(intYear) || isNaN(intMonth) || isNaN(intDay)) return false
  if (intMonth > 12 || intMonth < 1) return false
  if (intDay < 1 || intDay > 31) return false
  if ((intMonth === 4 || intMonth === 6 || intMonth === 9 || intMonth === 11) && (intDay > 30)) return false
  if (intMonth === 2) {
    if (intDay > 29) return false
    if ((((intYear % 100 === 0) && (intYear % 400 !== 0)) || (intYear % 4 !== 0)) && (intDay > 28)) return false
  }
  return true
}

/**
 * 日付かどうかの判定
 * @param {*} year
 * @param {*} month
 * @param {*} day
 * @returns
 */
export function isValidDateTime(year, month, day, hour, minute) {
  var intYear = parseInt(year)
  var intMonth = parseInt(month)
  var intDay = parseInt(day)
  var intHour = parseInt(hour)
  var intMinute = parseInt(minute)

  if (isNaN(intYear) || isNaN(intMonth) || isNaN(intDay)) return false
  if (intMonth > 12 || intMonth < 1) return false
  if (intDay < 1 || intDay > 31) return false
  if ((intMonth === 4 || intMonth === 6 || intMonth === 9 || intMonth === 11) && (intDay > 30)) return false
  if (intMonth === 2) {
    if (intDay > 29) return false
    if ((((intYear % 100 === 0) && (intYear % 400 !== 0)) || (intYear % 4 !== 0)) && (intDay > 28)) return false
  }
  if (intHour > 23) return false
  if (intMinute > 59) return false
  return true
}
